export const enum MessageUI {
  STARTER = 'Inicialización de la aplicación',
  PAYED_OK = 'Su cita ya se encuentra pagada.',
  FREE_PAY_OK = 'Su cita ya se encuentra pagada.',
  GES_BONUS_OK = 'Su bono fue validado con éxito.',
  LETTER_PAY_OK = 'Su carta de resguardo fue validada con éxito.',
  HTTP_ERROR = 'Algo salio mal, por favor intenta nuevamente más tarde.',
  APPLYING_AGREEMENTS = 'Validación para aplicar descuento por convenio.',
  CANNOT_PAY_ONLINE = 'Lo sentimos, aún no es posible pagar este tipo de atenciones por este medio. Se habilitara prontamente.',
  PENDING_RECEIPT = 'Tiene un documento en revisión. Si este es aprobado, enviaremos el link de conexión a su correo el día de la cita.',
  FAIL_PAYMENT = `
    <section class="ion-padding">
      <h1 class="f-600">Su pago fue rechazado</h1>
      <h3>Las posibles causas de este rechazo son:</h3>
      <ul>
        <li><p>Su tarjeta de crédito o débito no tiene saldo suficiente.</p></li>
        <li><p>Su tarjeta aún no está habilitada en el sistema financiero</p></li>
        <li><p>Hubo un error al ingresar los datos de su tarjeta de crédito o débito (fecha y/o código de seguridad).</p></li>
      </ul>
    </section>
  `
}

export type NotificationType = {

  info: Notification;
  success: Notification;
  warning: Notification;
  error: Notification;
}

export type  IconMessage = "information-circle" | "checkmark-circle" | "alert-circle" | "close-circle"
export type ColorMessage = "secondary" | "success" | "warning" | "danger"
export type Notification = {
  icon: IconMessage
  color: ColorMessage,
  duration: 1500 | 2000 | 3000 | 5000 | 10000,
}

export interface ItemMessage {
  icon: IconMessage,
  message: string,
  color: ColorMessage
}

export const notification: NotificationType = {

  info: {
    duration: 2000,
    color: "secondary",
    icon: "information-circle"
  },
  success: {
    duration: 2000,
    color: "success",
    icon: "checkmark-circle"
  },
  warning: {
    duration: 3000,
    color: "warning",
    icon: "alert-circle"
  },
  error: {
    duration: 5000,
    color: "danger",
    icon: "close-circle"
  }
}

export enum AppState {
  EMPTY = '',
  HTTP_ERROR = 'HTTP_ERROR',
  ERROR = 'ERROR',
  INVALID = 'INVALIDO',
  SUCCESS = 'ÉXITO',
  LOADING = 'CARGANDO',
  FAIL_PAYMENT = 'FAIL_PAYMENT'

}

export interface UIState {
  notification: Notification;
  message: string;
  isLoading: boolean;
  status: AppState;
}
