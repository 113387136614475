import {inject, Injectable, signal} from '@angular/core';
import {AppState, MessageUI, notification, UIState} from "@interfaces/ui-state.interface";
import {ToastController} from "@ionic/angular";
import {addIcons} from "ionicons";
import {alertCircle, checkmarkCircle, closeCircle, informationCircle} from "ionicons/icons";

@Injectable({
  providedIn: 'root'
})
export class UiService {

  public UIInitialState: UIState = {
    isLoading: false,
    status: AppState.EMPTY,
    notification: notification.info,
    message: MessageUI.STARTER,
  }

  public state = signal<UIState>(this.UIInitialState)

  private toastController = inject(ToastController);

  constructor() {
    addIcons({informationCircle, checkmarkCircle, closeCircle, alertCircle});
  }

  reset() {
    this.state.update(_ => this.UIInitialState)
  }

  async presentToast(state: UIState) {

    const toast = await this.toastController.create({
      position: 'bottom',
      message: state.message,
      icon: state.notification.icon,
      color: state.notification.color,
      duration: state.notification.duration,
      buttons: [{icon: 'close-circle'}],

    });

    await toast.present();
  }
}
