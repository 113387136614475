export const endpointMessages: {
  [key: string]: { [method: string]: { start: string; end: string; error: string } }
} = {
  'agenda/v1/citas/[\\w-]+': {
    GET: {
      start: 'Estamos buscando información de su cita.',
      end: 'Hemos encontrado su cita.',
      error: 'Algo salió mal al intentar obtener información de la cita. Por favor, inténtalo nuevamente más tarde.'
    },
    PUT: {
      start: 'Actualizando información de la cita.',
      end: 'Actualización de cita completada.',
      error: 'Algo salió mal al intentar actualizar la cita. Por favor, inténtalo nuevamente más tarde.'
    }
  },
  'agenda/v1/citas$': {
    GET: {
      start: 'Obteniendo información de las citas.',
      end: 'Proceso de búsqueda de citas completado.',
      error: 'Algo salió mal al intentar obtener información de las citas. Por favor, inténtalo nuevamente.'
    },
    POST: {
      start: 'Creando cita.',
      end: 'Creación de cita completada.',
      error: 'Algo salió mal al intentar crear la cita. Por favor, inténtalo nuevamente más tarde.'
    }
  },
  'status': {
    GET: {
      start: 'Obteniendo información de resoluciones de citas.',
      end: 'Proceso de búsqueda de resoluciones de citas completado.',
      error: 'Algo salió mal al intentar obtener información de las resoluciones de citas. Por favor, inténtalo nuevamente.'
    },
  },
  '\b(pagos|link)\b.*\b(link|pagos)\b': {
    GET: {
      start: 'Estamos buscando obteniendo los datos necesarios para iniciar el proceso de pago.',
      end: 'Redirigiendo al proceso de pago',
      error: 'Algo salió mal al intentar obtener información para iniciar el pago de la cita.'
    }
  },
  'pagos/v1/citas': {
    GET: {
      start: 'Obteniendo información de los pagos de las citas.',
      end: 'Proceso de búsqueda de pagos de citas completado.',
      error: 'Algo salió mal al intentar obtener información de los pagos de las citas. Por favor, inténtalo nuevamente.'
    },
    POST: {
      start: 'Procesando pago de la cita.',
      end: 'Procesamiento del pago de la cita completado.',
      error: 'Algo salió mal al intentar procesar el pago de la cita. Por favor, inténtalo nuevamente más tarde.'
    },
    PUT: {
      start: 'Actualizando información del pago de la cita.',
      end: 'Actualización del pago de la cita completada.',
      error: 'Algo salió mal al intentar actualizar el pago de la cita. Por favor, inténtalo nuevamente más tarde.'
    }
  },
  'almacenamiento/v1/url': {
    GET: {
      start: 'Obteniendo los datos necesarios para el almacenamiento.',
      end: 'Recuperación de URL de almacenamiento completada.',
      error: 'Algo salió mal al intentar obtener la URL de almacenamiento. Por favor, inténtalo nuevamente.'
    },
    POST: {
      start: 'Obteniendo permisos del almacenamiento.',
      end: 'Premisos del almacenamiento concedidos.',
      error: 'Algo salió mal al intentar obtener permisos para cargar archivos.'
    },
    PUT: {
      start: 'Actualizando URL en el almacenamiento.',
      end: 'Actualización de URL en el almacenamiento completada.',
      error: 'Algo salió mal al intentar actualizar la URL en el almacenamiento. Por favor, inténtalo nuevamente más tarde.'
    }
  },
  's3.us-east-1.amazonaws.com': {
    PUT: {
      start: 'Subiendo archivos.',
      end: 'Proceso de carga de archivos completado.',
      error: 'Algo salió mal al intentar subir el archivo.'
    }
  }
};
