import {Routes} from '@angular/router';
import {authGuard, childAuthGuard} from "@shared/guards/auth.guard";

export const routes: Routes = [
  {
    path: 'inicio',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },
  {
    path: 'pago',
    loadComponent: () => import('./payments/payments.page').then(m => m.PaymentsPage),
    children: [
      {
        path: 'atencion',
        title: 'Pago de Atenciones',
        loadComponent: () => import('@payments/attention/attention.page').then(m => m.AttentionPage),
      },
      {
        path: 'atencion/:appointmentId',
        title: 'Pago de Atenciones',
        loadComponent: () => import('@payments/attention/attention.page').then(m => m.AttentionPage),
      },
      {
        path: 'atencion/:appointmentId/:redirectionCode',
        title: 'Pago de Atenciones',
        loadComponent: () => import('@payments/attention/attention.page').then(m => m.AttentionPage),
      },
      {
        path: "comprobante",
        title: 'Comprobante de Pago',
        loadComponent: () => import('./payments/voucher/voucher.page').then(m => m.VoucherPage),
      },
      {
        path: '', redirectTo: '/inicio', pathMatch: 'full',
      }
    ]

  },
  {
    path: "autenticar",
    title: 'Inicio de Sesión | CMV Backoffice',
    loadComponent: () => import('./backoffice/auth/auth.component').then(m => m.AuthComponent),
  },
  {

    path: 'backoffice',
    title: 'Backoffice | Centro Médico Virtual',
    loadComponent: () => import('./backoffice/backoffice.page').then(m => m.BackofficePage),
    canActivate: [authGuard],
    canActivateChild: [childAuthGuard],
    children: [
      {
        path: 'recibos/pendientes',
        title: 'Backoffice | Resoluciones',
        loadComponent: () => import('./backoffice/dashboard/dashboard.page').then(m => m.DashboardPage),
      },
      {
        path: 'reportes/citas/pagos',
        title: 'Backoffice | Reportes | Pagos',
        loadComponent: () => import('./backoffice/report-payments/report-payments.component').then(m => m.ReportPaymentsComponent),
      },
      {
        path: 'reportes/recibos/resueltos',
        title: 'Backoffice | Reportes | Recibos',
        loadComponent: () => import('./backoffice/report-receipts/report-receipts.component').then(m => m.ReportReceiptsComponent),
      },
      {
        path: 'reportes',
        title: 'Backoffice | Reportes',
        loadComponent: () => import('./backoffice/report-list/report-list.component').then(m => m.ReportListComponent),
      },
      {
        path: 'reportes/incidencias',
        title: 'Backoffice | Incidencias | Reportes',
        loadComponent: () => import('./backoffice/report-issues/report-issues.component').then(m => m.ReportIssuesComponent),
      },
      {
        path: 'citas/:appointmentId',
        title: 'Backoffice | Cita',
        loadComponent: () => import('./shared/components/appointment/appointment.component').then(m => m.AppointmentComponent),
      },
      {
        path: '', redirectTo: 'resoluciones', pathMatch: 'full',
      }
    ]
  },
  {
    path: '',
    redirectTo: '/inicio',
    pathMatch: 'full',
  }
];
